import { updateUserService } from '../../services/user';
import { uploadImage } from '../../config/awsConfig';

const updateAccountBankService = async (accountBankToUpdate, support, fileName, userId) => {
  try {
    const savedImage = await uploadImage(support, fileName, 'docs.shipping/bankCertifications');
    const accountBank = { ...accountBankToUpdate.accountBank, certificate: savedImage };
    return await updateUserService(userId, { accountBank, code: accountBankToUpdate.code });
  } catch (error) {
    return {
      error: true,
      message: error?.publicMessage,
    };
  }
};

export default updateAccountBankService;
